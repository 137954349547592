import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/next@15.1.0_@babel+core@7.26.0_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom@1_r7yqi22cjoh2nog6ek5voc7z3e/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/next@15.1.0_@babel+core@7.26.0_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom@1_r7yqi22cjoh2nog6ek5voc7z3e/node_modules/next/dist/client/components/client-segment.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/next@15.1.0_@babel+core@7.26.0_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom@1_r7yqi22cjoh2nog6ek5voc7z3e/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/next@15.1.0_@babel+core@7.26.0_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom@1_r7yqi22cjoh2nog6ek5voc7z3e/node_modules/next/dist/client/components/http-access-fallback/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/next@15.1.0_@babel+core@7.26.0_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom@1_r7yqi22cjoh2nog6ek5voc7z3e/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/next@15.1.0_@babel+core@7.26.0_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom@1_r7yqi22cjoh2nog6ek5voc7z3e/node_modules/next/dist/client/components/render-from-template-context.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/next@15.1.0_@babel+core@7.26.0_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom@1_r7yqi22cjoh2nog6ek5voc7z3e/node_modules/next/dist/lib/metadata/metadata-boundary.js");
